import React from 'react';

function Spray(){
    return(
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg"  id="icon" x="0px" y="0px" viewBox="0 0 124.6 122" >
            <g id="spray">
                <path className="st8" d="M69.9,120.7H13.8c-2.4,0-4.4-2-4.4-4.4v0c0-2.4,2-4.4,4.4-4.4h56.1c0.6,0,1.1,0.1,1.6,0.3
                    c1.6,0.7,2.8,2.3,2.8,4.1v0C74.3,118.7,72.3,120.7,69.9,120.7z"/>
                <path className="st8" d="M13.8,111.8V94.3c0,0-3.6-1.7-3.4-8.2V62.8"/>
                <path className="st8" d="M13.8,94.3c0,0,5.1,0.6,6.3-4.9V43.5h-9.3v12.4"/>
                <path className="st8" d="M20,43.5"/>
                <path className="st8" d="M69.7,43.5h-6.1h-7.9h-14h-4.6H13.7c-2.4,0-4.4-2-4.4-4.4v0c0-2.4,2-4.4,4.4-4.4H16h21.1h20.8h5.7h6.1
                    c2.4,0,4.4,2,4.4,4.4v0c0,1.9-1.3,3.6-3,4.2C70.7,43.4,70.2,43.5,69.7,43.5z"/>
                <path className="st8" d="M37.1,43.5l0.2,8.7c0,0-0.4,1.2,1.7,2.5l6.4,4.7c0,0,1.4-0.1,1.7,3.9v14.8c0,0,0.9,3.7,4.4,3.7
                    c0,0,4.1,0,4.1-3.7v-7.3"/>
                <line className="st8" x1="55.7" y1="43.5" x2="55.7" y2="64.5"/>
                <line className="st8" x1="71.2" y1="43.2" x2="71.2" y2="97.3"/>
                <line className="st8" x1="71.5" y1="112.1" x2="71.5" y2="104.1"/>
                <path className="st8" d="M37.9,103.9h-2.6c-1.9,0-3.5-1.6-3.5-3.5v-7.8c0-1.9,1.6-3.5,3.5-3.5h2.6c1.9,0,3.5,1.6,3.5,3.5v7.8
                    C41.4,102.3,39.8,103.9,37.9,103.9z"/>
                <polyline className="st8" points="41.8,43.5 41.8,43 41.8,34.7 	"/>
                <line className="st8" x1="63.6" y1="43.5" x2="63.6" y2="34.6"/>
                <path className="st8" d="M16,34.6c0,0-2.4-6.9,7-12.1h13.8H58h3.4c0,0,8.8,5.6,8.4,12.1"/>
                <line className="st8" x1="37" y1="22.4" x2="37" y2="34.6"/>
                <line className="st8" x1="58" y1="22.5" x2="57.9" y2="34.6"/>
                <path className="st8" d="M58.9,22.5H25.8c-2,0-3.7-1.7-3.7-3.7V18c0-2,1.7-3.7,3.7-3.7h7.5h18.2h7.4c2,0,3.7,1.7,3.7,3.7v0.8
                    C62.6,20.8,60.9,22.5,58.9,22.5z"/>
                <path className="st8" d="M51.4,14.3l0-0.4V6.9c-0.1-4.2-5.2-4.4-5.2-4.4h-4.4c-7.7-0.4-8.6,4.2-8.6,4.2v7.4"/>
                <path className="st9" d="M44.8,10.7h-1.7c-0.6,0-1.1-0.5-1.1-1.1V8.8c0-0.6,0.5-1.1,1.1-1.1h1.7c0.6,0,1.1,0.5,1.1,1.1v0.8
                    C45.9,10.2,45.4,10.7,44.8,10.7z"/>
                <line className="st8" x1="69.9" y1="8.3" x2="114.1" y2="3.1"/>
                <line className="st8" x1="70.7" y1="18.3" x2="114" y2="23.2"/>
            </g>
        </svg>

    )
}

export default Spray;