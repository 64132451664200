import React from 'react';

function Standard4(){
    return(
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" id="standard" x="0px" y="0px" viewBox="0 0 124.6 122">
            <g id="standard4">
                <path className="st8" d="M22.3,62.4H11c-1.4,0-2.6-1.1-2.6-2.6v-0.4c0-1.4,1.1-2.6,2.6-2.6h11.3c1.4,0,2.6,1.1,2.6,2.6v0.4
                    C24.9,61.3,23.7,62.4,22.3,62.4z"/>
                <path className="st8" d="M36.5,91.5l-8,8c-1,1-2.6,1-3.6,0l-0.3-0.3c-1-1-1-2.6,0-3.6l8-8c1-1,2.6-1,3.6,0l0.3,0.3
                    C37.5,88.9,37.5,90.5,36.5,91.5z"/>
                <path className="st8" d="M32.8,31.9l-8.3-7.6c-1-1-1.1-2.6-0.2-3.6l0.2-0.3c1-1,2.6-1.1,3.6-0.2l8.3,7.6c1,1,1.1,2.6,0.2,3.6l-0.2,0.3
                    C35.4,32.8,33.8,32.9,32.8,31.9z"/>
                <path className="st8" d="M61.5,17.8V6.6C61.5,5.1,62.6,4,64,4h0.4c1.4,0,2.6,1.1,2.6,2.6v11.3c0,1.4-1.1,2.6-2.6,2.6H64
                    C62.6,20.4,61.5,19.2,61.5,17.8z"/>
                <path className="st8" d="M103.6,24l-8.3,8.3c-0.9,0.9-2.4,0.9-3.3,0l-0.6-0.6c-0.9-0.9-0.9-2.4,0-3.3l8.3-8.3c0.9-0.9,2.4-0.9,3.3,0
                    l0.6,0.6C104.5,21.7,104.5,23.1,103.6,24z"/>
                <path className="st8" d="M116.8,62.6h-11.3c-1.4,0-2.6-1.1-2.6-2.6v-0.4c0-1.4,1.1-2.6,2.6-2.6h11.3c1.4,0,2.6,1.1,2.6,2.6V60
                    C119.3,61.4,118.2,62.6,116.8,62.6z"/>
                <path className="st8" d="M98.9,99.1l-8-8c-1-1-1-2.6,0-3.6l0.3-0.3c1-1,2.6-1,3.6,0l8,8c1,1,1,2.6,0,3.6l-0.3,0.3
                    C101.5,100.1,99.9,100.1,98.9,99.1z"/>
                <path className="st8" d="M56.3,116.4h14.4c-0.7-6.4,4.3-7.6,4.3-7.6c3.4-4.5-0.1-8.9-0.1-8.9H52.1c0,0-2.6,7.1,1.2,10.3
                    C53.3,110.2,57.4,110.9,56.3,116.4z"/>
                <path className="st8" d="M63.8,30.9c-15.8,0-28.6,12.8-28.6,28.6c0,7.5,2.9,14.4,7.7,19.5c0,0,0.2,0.2,0.6,0.6c2,2.1,8.4,9.6,8.4,18.1
                    h23.2C75,90.2,81.3,82.7,83.8,80c0.1-0.1,0.3-0.3,0.4-0.4c0.1-0.2,0.3-0.3,0.4-0.4c0.1-0.1,0.2-0.2,0.2-0.2
                    c4.7-5.1,7.6-11.9,7.6-19.5C92.4,43.7,79.6,30.9,63.8,30.9z M63.7,45.7c0,0-13.2-0.4-14.1,14c0,1.8-1.5,3.3-3.3,3.3
                    c-1.8,0-3.3-1.5-3.3-3.3c0-0.2,0-0.5,0.1-0.7c2.4-21.7,20.7-19.9,20.7-19.9c1.8,0,3.3,1.5,3.3,3.3C67,44.2,65.5,45.7,63.7,45.7z"/>
                <path className="st9" d="M67,42.4c0,1.8-1.5,3.3-3.3,3.3c0,0-13.2-0.4-14.1,14c0,1.8-1.5,3.3-3.3,3.3c-1.8,0-3.3-1.5-3.3-3.3
                    c0-0.2,0-0.5,0.1-0.7c2.4-21.7,20.7-19.9,20.7-19.9C65.5,39.1,67,40.6,67,42.4z"/>
            </g>
        </svg>
    )
}

export default Standard4;