import React from 'react';

function Building(){
    return(
<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" id="icon" viewBox="0 0 124.6 122">
<g id="building">
	<path className="st1" d="M13.3,105.6V17.4c0,0,0.3-3.8,6.8-2.4l47,7.1c0,0,1.9,0.2,2,3.8v24.5l41.7,10c0,0,1.5,0.6,1.7,4.2v43.6
		c0,0,0,3.3-4.2,3.5H64.5H30.7H17.6C17.6,111.8,13.3,112.1,13.3,105.6z"/>
	<path className="st1" d="M30.7,111.8V89.5c0,0,0.2-3.9,4.6-3.8h13.9c0,0,2.5,0.2,2.6,3.3V112L30.7,111.8z"/>
	<line className="st1" x1="69.1" y1="50.4" x2="69.1" y2="111.5"/>
	<path className="st2" d="M35.9,36.6h-9.8c-0.4,0-0.7-0.3-0.7-0.7v-2.1c0-0.4,0.3-0.7,0.7-0.7h9.8c0.4,0,0.7,0.3,0.7,0.7v2.1
		C36.6,36.3,36.2,36.6,35.9,36.6z"/>
	<path className="st2" d="M56.7,36.5H47c-0.4,0-0.7-0.3-0.7-0.7v-2.1c0-0.4,0.3-0.7,0.7-0.7h9.8c0.4,0,0.7,0.3,0.7,0.7v2.1
		C57.4,36.2,57.1,36.5,56.7,36.5z"/>
	<path className="st2" d="M35.8,49H26c-0.4,0-0.7-0.3-0.7-0.7v-2.1c0-0.4,0.3-0.7,0.7-0.7h9.8c0.4,0,0.7,0.3,0.7,0.7v2.1
		C36.5,48.6,36.2,49,35.8,49z"/>
	<path className="st2" d="M56.6,48.9h-9.8c-0.4,0-0.7-0.3-0.7-0.7V46c0-0.4,0.3-0.7,0.7-0.7h9.8c0.4,0,0.7,0.3,0.7,0.7v2.1
		C57.3,48.5,57,48.9,56.6,48.9z"/>
	<path className="st2" d="M35.7,62.2h-9.8c-0.4,0-0.7-0.3-0.7-0.7v-2.1c0-0.4,0.3-0.7,0.7-0.7h9.8c0.4,0,0.7,0.3,0.7,0.7v2.1
		C36.4,61.9,36.1,62.2,35.7,62.2z"/>
	<path className="st2" d="M56.5,62.1h-9.8c-0.4,0-0.7-0.3-0.7-0.7v-2.1c0-0.4,0.3-0.7,0.7-0.7h9.8c0.4,0,0.7,0.3,0.7,0.7v2.1
		C57.2,61.8,56.9,62.1,56.5,62.1z"/>
	<path className="st2" d="M35.7,74.6h-9.8c-0.4,0-0.7-0.3-0.7-0.7v-2.1c0-0.4,0.3-0.7,0.7-0.7h9.8c0.4,0,0.7,0.3,0.7,0.7v2.1
		C36.4,74.3,36.1,74.6,35.7,74.6z"/>
	<path className="st2" d="M56.5,74.5h-9.8c-0.4,0-0.7-0.3-0.7-0.7v-2.1c0-0.4,0.3-0.7,0.7-0.7h9.8c0.4,0,0.7,0.3,0.7,0.7v2.1
		C57.2,74.2,56.9,74.5,56.5,74.5z"/>
	<path className="st2" d="M95.6,75.1h-9.8c-0.4,0-0.7-0.3-0.7-0.7v-2.1c0-0.4,0.3-0.7,0.7-0.7h9.8c0.4,0,0.7,0.3,0.7,0.7v2.1
		C96.3,74.8,96,75.1,95.6,75.1z"/>
	<path className="st2" d="M95.3,87.9h-9.8c-0.4,0-0.7-0.3-0.7-0.7v-2.1c0-0.4,0.3-0.7,0.7-0.7h9.8c0.4,0,0.7,0.3,0.7,0.7v2.1
		C96,87.6,95.7,87.9,95.3,87.9z"/>
	<path className="st2" d="M95.4,100.6h-9.8c-0.4,0-0.7-0.3-0.7-0.7v-2.1c0-0.4,0.3-0.7,0.7-0.7h9.8c0.4,0,0.7,0.3,0.7,0.7v2.1
		C96.1,100.3,95.8,100.6,95.4,100.6z"/>
</g>
</svg>

    )
}

export default Building;