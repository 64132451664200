import React from 'react';

function Shield(){
    return(
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" id="icon" x="0px" y="0px" viewBox="0 0 124.6 122">
            <g id="shield">
                <path className="st8" d="M12.7,27.7L59.3,3.9c0,0,4.4-2.9,10.1,0l45.1,23.8c0,0,4.5,39.4-20,68.9c0,0-13,15.8-28.6,21.9
                    c0,0-1.6,1.3-8.3-1.7c0,0-27.7-12.7-39.7-46.3C17.9,70.4,10.7,41.7,12.7,27.7z"/>
                <path className="st9" d="M26,37.1c0,0-2.7,41.8,33.5,65.5c0,0,3.9,3.7,7.4,0c0,0,30.2-10.3,34.2-65.5L65.9,18.2c0,0-2.9-1-4,0L26,37.1z
                    "/>
                <path className="st8" d="M33.4,40.6l28-14.3c0,0,2.2-1.5,5.7,0L93,40c0,0,1.9,34.5-27.8,55.8c0,0-1.9,1.3-3.9,0
                    C61.3,95.7,34.7,79.4,33.4,40.6z"/>
            </g>
        </svg>
    )
}

export default Shield;